<template>
    <tr class="border-b-[1px] border-[#02130E]">
        <td class="pl-3 pr-2 w-[5%] py-2 text-start text-[14px] ">
            <div class="w-10 h-10 border-[#FFF] border-[1px] rounded-full overflow-hidden">
                <img v-if="student.photo" :src="studentLogo">
                <div v-else class="h-full w-full flex justify-center items-center text-[#FFF] bg-[#FFF] bg-opacity-20">{{ student.name[0] + student.surname[0] }}</div>
            </div>
        </td>
        <td 
            class="px-3 truncate py-2 w-[15%] text-start text-[14px] "
            :class="{
                'w-[15%]' : screenWidth > 1450,
                'w-[10%]' : screenWidth < 1450
            }"    
        >
            <div @click="$emit('openStudentCard')" class="flex flex-col align-middle cursor-pointer">
                <div class="truncate text-[#FFF] text-[14px]">{{ student.surname }} {{ student.name }}</div>
                <div class="truncate text-[10px] text-gray-400">{{ '#' + student.player_number + ' | ' + student.amplua + ' | ' + student.age + 'p. | ' + student.nationality }}</div>
            </div>
        </td>
        <td 
            class="px-3 w-[10%] truncate py-2 text-start text-[14px] text-[#FFF] "
            :class="{
                'w-[10%]' : screenWidth > 1450,
                'w-[5%]' : screenWidth < 1450
            }"      
        >{{ student.group_name.length > 0 ? student.group_name : '—' }}</td>
        <td class="px-3 w-[10%] py-2 text-start text-[14px] text-[#FFF] ">{{ student.phone }}</td>
        <td v-if="screenWidth > 1450" class="w-[5%] "></td>
        <td class="px-2 w-[204px] py-2 flex justify-end items-center h-[55px] text-[14px] ">
            <div class="flex justify-start" v-for="s in student.status">
                <div v-if="activeToDate <= dateNow" class="bg-[#F9245A] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                    Тариф
                </div> 
                <div v-else class="w-[88px] mx-2"></div>
                <div v-if="s == 'active'" class="bg-[#5AC47D] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                    <div class="block">
                        Активний
                    </div>
                </div>  
                <div v-if="s == 'archive'" class="bg-[#D9D9D9] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-[#78797C]">
                    Архів
                </div> 
            </div>
        </td>
        <td 
            class="py-2 text-end text-sm w-[35%] "
            :class="{
                'w-[35%] px-3' : screenWidth > 1450,
                'w-[35%] px-2' : screenWidth < 1450
            }"    
        >
            <button 
                @click="$emit('openStudentCard')" 
                class="py-1 rounded-[10px] text-sm font-medium"
                :class="{
                    'px-2' : screenWidth > 1450,
                    'px-[4px]' : screenWidth < 1450
                }"        
            >
                <img 
                    src="@/assets/icons/pen_icon.svg" 
                    class="w-6 h-6"
                >
            </button>
            <button 
                v-if="student.status.includes('archive')"
                @click="$emit('removeStudentFromArchive')" 
                class="py-1 rounded-[10px] text-sm font-medium"
                :class="{
                    'px-2' : screenWidth > 1450,
                    'px-[4px]' : screenWidth < 1450
                }"        
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 7C18.7745 7.16058 19.3588 7.42859 19.8284 7.87589C21 8.99181 21 10.7879 21 14.38C21 17.9721 21 19.7681 19.8284 20.8841C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8841C3 19.7681 3 17.9721 3 14.38C3 10.7879 3 8.99181 4.17157 7.87589C4.64118 7.42859 5.2255 7.16058 6 7" stroke="#41BF74" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M12.0253 2.00052L12 14M12.0253 2.00052C11.8627 1.99379 11.6991 2.05191 11.5533 2.17492C10.6469 2.94006 9 4.92886 9 4.92886M12.0253 2.00052C12.1711 2.00657 12.3162 2.06476 12.4468 2.17508C13.3531 2.94037 15 4.92886 15 4.92886" stroke="#41BF74" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <button 
                @click="$emit('deleteStudent')" 
                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                :class="{
                    'px-2' : screenWidth > 1450,
                    'px-[4px]' : screenWidth < 1450
                }"      
            >
                <img 
                    src="@/assets/icons/trash_icon.svg" 
                    class="w-6 h-6"   
                >
            </button>
        </td>
    </tr>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import { getBalanceState } from '@/services/apiRequests'
    import { Skeletor } from 'vue-skeletor'

    export default {
        name: 'student-row',

        props: {
            student: {
                type: Object,
                required: true
            }
        },

        setup(props) {
            const studentLogo = computed(
                () => process.env.VUE_APP_API_URL + 'static/' + props.student.photo ? process.env.VUE_APP_API_URL + 'static/' + props.student.photo : require('@/assets/student_photo_placeholder.svg')
            )
            const studentBalance = ref(props.student.balance)
            const screenWidth = ref(window.innerWidth)

            const dateNow = new Date()
            const activeToDate = new Date(props.student.date_active_to)

            onMounted(async () => {
                console.log('student balance', props.student.balance)
                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                console.log('props.student.date_active_to ------- ', props.student.date_active_to)
                // studentBalance.value = await getBalanceState(props.student.balance_id)
            })

            const formatDate = (date) => {
                const dateArray = date.split('-')
                return `${dateArray[2].slice(0, 2)}/${dateArray[1]}/${dateArray[0]}`
            }

            const test = () => {
                console.log('test')
            }

            return {
                studentLogo,
                studentBalance,
                formatDate,
                test,
                screenWidth,
                dateNow,
                activeToDate
            }
        },

        components: {
            Skeletor
        }
    }
</script>

<style lang="scss" scoped>

    .vue-skeletor {
        background-color: #ccc;
        animation: shimmer 1.5s infinite;
        animation-duration: 2s; 
        animation-timing-function: ease-in-out;
        border-radius: 10px;
    }

    /* Default keyframes used in skeletor */
    @keyframes shimmer {
        0% {
            opacity: .5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: .5;
        }
    }

</style>